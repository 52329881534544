<template>
  <div>
    <div class="list-title">
      {{ $t("alarmReceiver") }}
    </div>

    <el-button type="primary" plain size="small" @click="addReceiver" style="margin: 10px 0;">
      + {{ $t("handle.addReceiver") }}
    </el-button>
    <el-table :data="receiverList" v-loading="loading">
      <el-table-column prop="name" sortable :label="$t('name')"> </el-table-column>

      <el-table-column :label="$t('receiveType')" width="700">
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.email_configs ||
                scope.row.slack_configs ||
                scope.row.wechat_configs ||
                scope.row.webhook_configs
            "
          >
            <div v-if="scope.row.email_configs" class="configs-container">
              <div class="title">SMTP</div>
              <div class="desc" :title="item.to" v-for="(item, index) in scope.row.email_configs" :key="index">
                {{ item.to }}
              </div>
            </div>

            <div v-if="scope.row.slack_configs" class="configs-container">
              <div class="title">Slack</div>
              <div class="desc" :title="item.channel" v-for="(item, index) in scope.row.slack_configs" :key="index">
                {{ item.channel }}
              </div>
            </div>

            <div v-if="scope.row.wechat_configs" class="configs-container">
              <div class="title">WeChat</div>
              <div class="desc" :title="item.corp_id" v-for="(item, index) in scope.row.wechat_configs" :key="index">
                {{ item.corp_id }}
              </div>
            </div>

            <div v-if="scope.row.webhook_configs" class="configs-container">
              <div class="title">{{ $t("phoneMessage") }}</div>
              <div class="desc" :title="item.url" v-for="(item, index) in scope.row.webhook_configs" :key="index">
                {{ item.url }}
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('handle.handle')">
        <template slot-scope="scope">
          <el-button plain size="small" @click="edit(scope.row)">
            {{ $t("handle.edit") }}
          </el-button>

          <el-button type="danger" plain size="small" @click="deleteReceiver(scope.row)">
            {{ $t("handle.delete") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="$t('receiverConf')"
      :visible.sync="dialogVisible"
      top="30px"
      width="1200px"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-position="top" size="small" ref="form">
        <el-form-item
          :label="$t('name')"
          prop="name"
          :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item :label="$t('receiveType')">
          <el-button
            type="default"
            size="small"
            plain
            icon="plus"
            @click="form.email_configs.push({ to: '', send_resolved: true })"
          >
            + {{ $t("handle.addEmailConf") }}
          </el-button>

          <el-button type="default" size="small" plain icon="plus" @click="addSlackItem">
            + {{ $t("handle.addSlackConf") }}
          </el-button>

          <el-button
            type="default"
            size="small"
            plain
            icon="plus"
            @click="
              form.wechat_configs.push({ to_party: '', agent_id: '', corp_id: '', api_secret: '', send_resolved: true })
            "
          >
            + {{ $t("handle.addWeChatConf") }}
          </el-button>

          <el-button
            type="default"
            size="small"
            plain
            icon="plus"
            @click="form.webhook_configs.push({ url: '', telTo: [{ phone: '' }], send_resolved: true })"
          >
            + {{ $t("handle.addPhoneConf") }}
          </el-button>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-card
              shadow="never"
              v-for="(email, index) in form.email_configs"
              :key="'smtp' + index"
              class="main-card"
            >
              <div slot="header" class="receiver-header">
                <div>SMTP {{ index + 1 }}</div>

                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="form.email_configs.splice(index, 1)"
                  size="small"
                ></el-button>
              </div>
              <el-form-item
                :label="$t('emailHost')"
                :prop="'email_configs.' + index + '.to'"
                :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
              >
                <el-input v-model="email.to"></el-input>
              </el-form-item>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-card
              shadow="never"
              class="main-card"
              v-for="(slack, index) in form.slack_configs"
              :key="'slack' + index"
            >
              <div slot="header" class="receiver-header">
                <div>Slack {{ index + 1 }}</div>

                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="form.slack_configs.splice(index, 1)"
                  size="small"
                ></el-button>
              </div>

              <div>
                <el-form-item
                  :label="$t('slackChannel')"
                  :prop="'slack_configs.' + index + '.channel'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="slack.channel"></el-input>
                </el-form-item>

                <el-form-item v-if="slack.open">
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        label="text"
                        :prop="'slack_configs.' + index + '.text'"
                        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                      >
                        <el-input v-model="slack.text"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24">
                      <el-form-item
                        label="title"
                        :prop="'slack_configs.' + index + '.title'"
                        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                      >
                        <el-input v-model="slack.title"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>

                <el-form-item v-if="slack.open">
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                        label="title_link"
                        :prop="'slack_configs.' + index + '.title_link'"
                        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                      >
                        <el-input v-model="slack.title_link"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24">
                      <el-form-item
                        label="color"
                        :prop="'slack_configs.' + index + '.color'"
                        :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                      >
                        <el-input v-model="slack.color"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form-item>

                <div class="slack-more-conf" @click="slack.open = !slack.open">
                  <span v-if="slack.open"> {{ $t("handle.slideUpConf") }} <i class="el-icon-arrow-up"></i></span>
                  <span v-if="!slack.open">{{ $t("handle.moreConf") }} <i class="el-icon-arrow-down"></i></span>
                </div>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-card
              shadow="never"
              class="main-card"
              v-for="(wechat, index) in form.wechat_configs"
              :key="'wechat' + index"
            >
              <div slot="header" class="receiver-header">
                <div>WeChat {{ index + 1 }}</div>

                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="form.wechat_configs.splice(index, 1)"
                  size="small"
                ></el-button>
              </div>

              <div>
                <el-form-item
                  label="corp_id"
                  :prop="'wechat_configs.' + index + '.corp_id'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="wechat.corp_id"></el-input>
                  <span class="help">{{ $t("receiverTips.wechatCorpID") }}</span>
                </el-form-item>

                <el-form-item
                  label="to_party"
                  :prop="'wechat_configs.' + index + '.to_party'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="wechat.to_party"></el-input>
                </el-form-item>

                <el-form-item
                  label="agent_id"
                  :prop="'wechat_configs.' + index + '.agent_id'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="wechat.agent_id"></el-input>
                  <span class="help">{{ $t("receiverTips.wechatAgentID") }}</span>
                </el-form-item>

                <el-form-item
                  label="api_secret"
                  :prop="'wechat_configs.' + index + '.api_secret'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="wechat.api_secret"></el-input>
                  <span class="help">{{ $t("receiverTips.wechatApiSecret") }}</span>
                </el-form-item>
              </div>
            </el-card>
          </el-col>

          <el-col :span="6">
            <el-card
              shadow="never"
              v-for="(item, index) in form.webhook_configs"
              :key="'webhook' + index"
              class="main-card"
            >
              <div slot="header" class="receiver-header">
                <div>{{ $t("phoneMessage") }} {{ index + 1 }}</div>

                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="form.webhook_configs.splice(index, 1)"
                  size="small"
                ></el-button>
              </div>
              <el-form-item
                label="URL"
                :prop="'webhook_configs.' + index + '.url'"
                :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
              >
                <el-input v-model="item.url"></el-input>
              </el-form-item>

              <el-form-item :label="$t('phoneNumber')">
                <div
                  v-for="(tel, index) in item.telTo"
                  :key="index"
                  style="display: inline-block; margin: 0 8px 8px 0;"
                >
                  <el-input v-model="tel.phone" style="width: 240px;">
                    <template slot="prepend">+86</template>

                    <el-button slot="append" icon="el-icon-delete" @click="item.telTo.splice(index, 1)"></el-button>
                  </el-input>
                </div>

                <el-button type="default" size="small" plain icon="plus" @click="item.telTo.push({ phone: '' })">
                  + {{ $t("handle.addPhoneNumber") }}
                </el-button>
              </el-form-item>
            </el-card>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { receivers, addReceivers, deleteReceivers } from "api/alert";
import { merge, includes } from "lodash";
import qs from "qs";
export default {
  data() {
    return {
      receiverList: [],
      loading: true,
      dialogVisible: false,
      submitLoading: false,
      form: {
        name: "",
        email_configs: [],
        slack_configs: [],
        wechat_configs: [],
        webhook_configs: []
      }
    };
  },

  methods: {
    getReceivers() {
      this.loading = true;
      receivers().then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.receiverList = response.data;
        }
      });
    },

    deleteReceiver(row) {
      this.$confirm(this.$t("delete", [row.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          deleteReceivers(row.name).then(response => {
            if (response.code === 0) {
              this.getReceivers();
            }
          });
        })
        .catch(() => {});
    },

    addReceiver() {
      this.dialogVisible = true;
      this.form = {
        name: "",
        email_configs: [],
        slack_configs: [],
        wechat_configs: [],
        webhook_configs: []
      };
    },

    addSlackItem() {
      this.form.slack_configs.push({
        channel: "",
        text:
          "{{ range .Alerts }} {{ .Annotations.description}}\n {{end}} {{ .CommonAnnotations.username}} <{{.CommonAnnotations.link}}| click here>",
        title: "{{.CommonAnnotations.summary}}",
        title_link: "{{.CommonAnnotations.link}}",
        color: "{{.CommonAnnotations.color}}",
        open: false,
        send_resolved: true
      });
    },

    edit(row) {
      this.dialogVisible = true;
      this.form = merge(
        {
          name: "",
          email_configs: [],
          slack_configs: [],
          wechat_configs: [],
          webhook_configs: []
        },
        row
      );

      if (this.form.webhook_configs.length > 0) {
        this.form.webhook_configs.forEach(item => {
          this.$set(item, "telTo", []);
          if (includes(item.url, "?")) {
            let telObject = qs.parse(item.url.split("?")[1]);
            let telString = "telTo" in telObject ? telObject.telTo : undefined;

            item.url = item.url.replace(`?telTo=${telString}`, "");

            if (telString) {
              let telTo = [];
              telString.split(",").forEach(phone => {
                telTo.push({ phone });
              });

              this.$set(item, "telTo", telTo);
            }
          }
        });
      }

      this.form.slack_configs.forEach(item => {
        if (!item.open) this.$set(item, "open", false);
      });
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.submitLoading = true;

          if (this.form.webhook_configs.length > 0) {
            this.form.webhook_configs.forEach(item => {
              let str = "";

              if (item.telTo.length > 0) {
                item.telTo.forEach(tel => {
                  str += `${tel.phone},`;
                });
              }

              if (str) item.url += `?telTo=${str.substring(0, str.length - 1)}`;
            });
          }

          addReceivers(this.form).then(response => {
            this.submitLoading = false;
            if (response.code === 0) {
              this.dialogVisible = false;
              this.getReceivers();
            }
          });
        }
      });
    }
  },

  mounted() {
    this.getReceivers();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.slack-more-conf {
  font-size: 12px;
  color: $color-primary;
  cursor: pointer;
}

.receiver-header {
  @include flex();
}

.configs-container {
  width: 160px;
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  .title {
    @include title(14px);
  }

  .desc {
    font-size: 12px;
    color: $color-sub;
    @include text-overflow();
  }
}
</style>
